@import 'scss/variables/_commonColors.scss';

$primary: #003D68;
$primary-hover: #406E8E;
$secondary: #3FBFE7;
$light-secondary: #ECF8F8;
$secondary-hover: #02BE00;
$flat-secondary-hover: rgba(2, 190, 0, 0.08);
$tertiary: #1D558C;
$tertiary-hover: #184673;
$flat-tertiary-hover: rgba(24, 70, 115, 0.08);
$light-primary: #3FBFE7;
$light-primary-hover: #3FBFE7;
$flat-light-primary-hover: rgba(41, 89, 147, 0.08);
$error-red: #F44336;
$gray-light: #F7F7F9;
$gray-border: #EFEFEF;
$gray-disabled-day: #F3F3F3;
$green: #79C654;
$border-logo-sign-in: $primary;
$confirmed-turn: $green;
$cancelled-turn: $error;
$pending-turn: #F3CF60;
$unassign-turn: #A0041B;
$assigned-to-me-turn: #EBF8FD;

$primary-1: #E5ECF0;
$primary-2: #BFCFD9;
$primary-3: #406E8E;
$primary-4: #003D68;
$primary-5:  #001A45;
$gray-1: #C1C7D0;
$gray-2: #677489;
$light-2: #F4F5F7;
$light-3: #E4E6EA;
$dark-1: #091E42;
$warning-1: #FFF9E6;
$warning-5: #936214;
$error-1: #FFEBEE;
$error-4: #E80C2C;
$error-5: #D40A28;
$success-1: #E6F9F3;
$success-4: #118460;
$success-5: #107F5C;

$gray-disabled-day-boder: $light-3;

$color-map: (
  ut-topbar: (
    background: $primary-5,
    border: $gray-2,
    disabled-base: $gray-3,
    shadow: transparent
  ),
  dashboard: (
    footer: (
      background: $primary,
      shadow: $gray
    )
  ),
  manage-turns: (
    selection: $primary,
    filter-background: $primary-4,
    filter-hover: $primary-3,
    button-color: $primary-4,
    search-button-background: $primary-4,
    search-button-hover: $primary-3
  )
);

// sass-lint:disable no-misspelled-properties
// sass-lint:disable property-sort-order
:export {
  black: $black;
  white: $white;
  gray: $gray;
  gray2: $gray-2;
  grayLight2: $gray-light-2;
  disabledGray: $gray-2;
  errorRed: $error-red;
  primary: $primary;
  primaryHover: $primary-hover;
  secondary: $secondary;
  tertiary: $tertiary;
  lightPrimary: $light-primary;
  lightPrimaryHover: $light-primary-hover;
  flatLightPrimaryHover: $flat-light-primary-hover;
  tertiaryHover: $tertiary-hover;
  flatTertiaryHover: $flat-tertiary-hover;
  secondaryHover: $secondary-hover;
  flatSecondaryHover: $flat-secondary-hover;
  loadingBase: $primary;
  primary3: $primary-3;
  primary4: $primary-4;
}
