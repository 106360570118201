@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,500,600,700'); //sass-lint:disable-line no-url-domains, no-url-protocols
@import 'scss/variables/_fontFamilies.scss';
@import 'general.scss';
@import '../fonts/Swiss721.css';
@import '../fonts/edea/EdeaFont.css';

html,
body {
  @extend %body-#{$customer} !optional;
  height: 100%;
  margin: 0;
  padding: 0 !important;
}

svg {
  use {
    pointer-events: none;
  }
}

a {
  color: inherit;
}

%body-edelap {
  font-family: $swiss;
}

%body-eden {
  font-family: $helvetica;
}

%body-edes {
  font-family: $helvetica;
}

%body-edesa {
  font-family: $helvetica;
}

%body-edenor {
  font-family: $nunito;
}

%body-edea {
  font-family: 'Edea';
}
