@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  src: local('Helvetica Neue Light'), local('Helvetica Neue-Light'), url(./HelveticaNeue-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 100;
  src: local('Helvetica Neue UltraLight'), local('Helvetica Neue-UltraLight'), url(./HelveticaNeue-UltraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src: local('Helvetica Neue Regular'), local('Helvetica Neue-Regular'), url(./HelveticaNeue.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  src: local('Helvetica Neue SemiBold'), local('Helvetica Neue-SemiBold'), url(./HelveticaNeue-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 600;
  src: local('Helvetica Neue Bold'), local('Helvetica Neue-Bold'), url(./HelveticaNeue-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  src: local('Helvetica Neue ExtraBold'), local('Helvetica Neue-ExtraBold'), url(./HelveticaNeue-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  src: local('Helvetica Neue Light'), local('Helvetica Neue-Light'), url(./HelveticaNeue-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 100;
  src: local('Helvetica Neue UltraLight'), local('Helvetica Neue-UltraLight'), url(./HelveticaNeue-UltraLight.otf) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src: local('Helvetica Neue Regular'), local('Helvetica Neue-Regular'), url(./HelveticaNeue.otf) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  src: local('Helvetica Neue SemiBold'), local('Helvetica Neue-SemiBold'), url(./HelveticaNeue-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 600;
  src: local('Helvetica Neue Bold'), local('Helvetica Neue-Bold'), url(./HelveticaNeue-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  src: local('Helvetica Neue ExtraBold'), local('Helvetica Neue-ExtraBold'), url(./HelveticaNeue-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 300;
  src: local('Helvetica Neue Light'), local('Helvetica Neue-Light'), url(./HelveticaNeue-Light.eot) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 100;
  src: local('Helvetica Neue UltraLight'), local('Helvetica Neue-UltraLight'), url(./HelveticaNeue-UltraLight.eot) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src: local('Helvetica Neue Regular'), local('Helvetica Neue-Regular'), url(./HelveticaNeue.eot) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  src: local('Helvetica Neue SemiBold'), local('Helvetica Neue-SemiBold'), url(./HelveticaNeue-Medium.eot) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 600;
  src: local('Helvetica Neue Bold'), local('Helvetica Neue-Bold'), url(./HelveticaNeue-Bold.eot) format('opentype');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 700;
  src: local('Helvetica Neue ExtraBold'), local('Helvetica Neue-ExtraBold'), url(./HelveticaNeue-Bold.eot) format('opentype');
}

