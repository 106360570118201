@font-face {
  font-family: 'Edea';
  font-style: normal;
  font-weight: normal;
  src: url(./Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Edea';
  font-weight: 800;
  src: url(./Roboto-Black.ttf);
}

@font-face {
  font-family: 'Edea';
  font-weight: 700;
  src: url(./Roboto-Bold.ttf);
}

@font-face {
  font-family: 'Edea';
  font-weight: 600;
  src: url(./Roboto-Medium.ttf);
}

@font-face {
  font-family: 'Edea';
  font-weight: 500;
  src: url(./Roboto-Medium.ttf);
}

@font-face {
  font-family: 'Edea';
  font-weight: 400;
  src: url(./Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Edea';
  font-weight: 300;
  src: url(./Roboto-Light.ttf);
}

@font-face {
  font-family: 'Edea';
  font-weight: 100;
  src: url(./Roboto-Thin.ttf);
}
