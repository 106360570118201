
@font-face {
  font-family: 'Swiss';
  font-style: normal;
  font-weight: 300;
  src: local('Swiss Light'), local('Swiss-Light'), url(./swiss721-light.ttf) format('truetype'), url(swiss721-light.eot);
}

@font-face {
  font-family: 'Swiss';
  font-style: normal;
  font-weight: 400;
  src: local('Swiss Regular'), local('Swiss-Regular'), url(./swiss721-regular.ttf) format('truetype'), url(swiss721-regular.eot);
}

@font-face {
  font-family: 'Swiss';
  font-style: normal;
  font-weight: 500;
  src: local('Swiss Medium'), local('Swiss-Medium'), url(./swiss721-medium.ttf) format('truetype'), url(swiss721-medium.eot);
}

@font-face {
  font-family: 'Swiss';
  font-style: normal;
  font-weight: 600;
  src: local('Swiss SemiBold'), local('Swiss-SemiBold'), url(./swiss721-semibold.ttf) format('truetype'), url(swiss721-semibold.eot);
}

@font-face {
  font-family: 'Swiss';
  font-style: normal;
  font-weight: 700;
  src: local('Swiss Bold'), local('Swiss-Bold'), url(./swiss721-semibold.ttf) format('truetype'), url(swiss721-semibold.eot);
}
