@import 'colors.scss';

.alertContentContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 250px;
  min-width: 250px;
}

.dialog {
  background-color: $alert-dialog-background;
}

.alertImage {
  max-width: 90px;
}

.title {
  margin-top: 15px;
}

.content {
  margin-top: 5px;
  max-width: 300px;
  text-align: center;
}

.accept {
  color: $white;
  text-transform: uppercase;
}
